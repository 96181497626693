<script setup lang="ts">
useSeoMeta({
  title: 'DartsSpace Scoring App'
})
</script>

<template>
  <QLayout view="hHh lpR lfr">
    <slot name="header" />
    <slot />
  </QLayout>
</template>
